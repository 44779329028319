<template>
    <div>
        <vx-card v-if="organizationInfo">

            <div slot="no-body" class="tabs-container px-6 pt-6">
                <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
                    <vs-tab :label="$t('menuOrganization.organization')" icon-pack="feather" icon="icon-user">
                        <div class="tab-text">
                            <organization-tab-information class="mt-4" :data="organizationInfo"/>
                        </div>
                    </vs-tab>
                    <vs-tab :label="$t('menuOrganization.keySetting')" icon-pack="feather" icon="icon-settings" v-if="$acl.check('organization')">
                        <div class="tab-text">
                            <key />
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </vx-card>
    </div>

</template>

<script>
import OrganizationTabInformation from "./OrganizationTabInformation";
import Key from '../../pages/user/GetKey.vue'

export default {
    components: {
        OrganizationTabInformation,
        Key
    },
    data() {
        return {
            organizationInfo: null,
            activeTab: 0,
        }
    },
    methods: {},
    created() {
        if (this.$route.fullPath == "/user/organization-info?setting"){
            this.activeTab = 1
        } else {
            this.activeTab = 0
        }
        this.$vs.loading();
        this.$oauth.post('/organization/info').then(res => {
            this.$vs.loading.close();
            this.organizationInfo = res.data;
        }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });
    },
}
</script>

<style scoped>

</style>

