<template>
    <div id="user-edit-tab-info">
        <!-- Content Row -->
        <div class="vx-row">
             <div class="vx-col md:w-2/12 w-full mt-4">
                <div class="avatar-col cursor-pointer" @click="editAvatar">
                    <div class="img-container mb-4" @mouseover="showEdit = true" @mouseout="showEdit = false">
                        <img v-if="!img" :src="userInfo.avatar" class="rounded w-full">
                        <img v-else :src="img" class="rounded w-full" >
                        <div class="component custom-edit m-auto text-white" v-show="showEdit" >
                            <feather-icon icon="Edit3Icon" style="width:1.5rem"></feather-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-col md:w-10/12 w-full">
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.organizationName')" disabled="true" v-model="organizationInfo.name"
                                data-vv-validate-on="blur"
                                v-validate="'required'" name="organizationName"/>
                        <span class="text-danger text-sm">{{ errors.first('organizationName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.acronym')" v-model="organizationInfo.acronym"
                                data-vv-validate-on="blur"
                                v-validate="'required|max:255'" name="organizationAcronym"/>
                        <span class="text-danger text-sm">{{ errors.first('organizationAcronym') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full " :label="$t('menuOrganization.email')" v-model="organizationInfo.email"  :disabled="true"
                                data-vv-validate-on="blur" v-validate="'required|email'"
                                name="emailModel"/>
                        <span class="text-danger text-sm">{{ errors.first('emailModel') }}</span>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full " :label="$t('menuOrganization.phoneNumber')" v-model="organizationInfo.phoneNumber"
                                data-vv-validate-on="blur" v-validate="'numeric|phone_rule|min:10|max:11'"
                                name="phoneNumber"/>
                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuOrganization.organizationType')}}</label>
                        <v-select class="" v-model="organizationType" :clearable="false" :options="organizationTypeOptions"
                                data-vv-validate-on="blur" v-validate="'required'" name="organizationType"/>
                        <span class="text-danger text-sm">{{ errors.first('organizationType') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuOrganization.expiredDate')}}</label>
                        <datepicker :language="languages['vi']" :format="'dd/MM/yyyy'" v-model="organizationInfo.expiredDate"
                                    data-vv-validate-on="input" v-validate="'required'" name="expiredDate"
                                    :disabled="true"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('expiredDate') }}</span>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuOrganization.city')}}</label>
                        <v-select class="" v-model="province" :clearable="false" :options="cityOptions"
                                data-vv-validate-on="blur" v-validate="'required'" name="city"/>
                        <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuOrganization.district')}}</label>
                        <v-select class="" v-model="district" :clearable="false" :options="districtOptions"
                                data-vv-validate-on="blur" v-validate="'required'" name="district"/>
                        <span class="text-danger text-sm">{{ errors.first('district') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuOrganization.commune')}}</label>
                        <v-select class="" v-model="subDistrict" :clearable="false" :options="subDistrictOptions"
                                data-vv-validate-on="blur" v-validate="'required'" name="subDistrict"/>
                        <span class="text-danger text-sm">{{ errors.first('subDistrict') }}</span>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.streetName')" v-model="organizationInfo.address.houseNumber"
                                v-validate="'required|max:50'" name="houseNumber"/>
                        <span class="text-danger text-sm">{{ errors.first('houseNumber') }}</span>
                    </div>
                    <div class="vx-col md:w-2/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.address')" v-model="fullAddressModel"
                                v-validate="''" name="full_address" readonly="true"/>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-2">
                        <vs-checkbox style="padding-top: 20px" v-model="active" false-value="0" true-value="1" :disabled="true">
                        {{$t('menuOrganization.activeStatus')}}
                        </vs-checkbox>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-2 flex flex-wrap items-center justify-end">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm" v-if="$acl.check('organization')">{{$t('button.update')}}</vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <my-upload
            field="img"
            v-model="showUploadImage"
            @crop-success="cropSuccess"
            :width="300"
            :height="300"
            img-format="png"
            :langExt  ="vn"
        >

        </my-upload>
    </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import * as lang from "vuejs-datepicker/dist/locale";
import province from '@/assets/utils/province.js';

export default {
    components: {
        vSelect,
        Datepicker,
        'my-upload': myUpload
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showEdit: false,
            fileData: [],
            showUploadImage: false,
            languages: lang,
            organizationInfo: {},
            organizationTypeOptions: [
                {label: this.$t("menuPackage.null"), value: null},
                {label: this.$t("menuPackage.education"), value: "education"},
                {label: this.$t("menuPackage.company"), value: "company"},
                {label: this.$t("menuPackage.shop"), value: "shop"},
            ],
            cityOptions: province.getProvince(),
            districtOptions: province.getDistrict('01'),
            subDistrictOptions: province.getSubDistrict('01', '001'),
            vn: {
                hint: 'Chọn ảnh cập nhật',
                loading: 'Đang cập nhật...',
                noSupported: 'Trình duyệt không hỗ trợ',
                primary: 'Cập nhật thành công',
                fail: 'Cập nhật thất bại',
                preview: 'Xem trước',
                btn: {
                    off: 'Hủy',
                    close: 'Đóng',
                    back: 'Quay lại',
                    save: 'Cập nhật'
                },
                error: {
                    onlyImg: 'Chỉ được đăng tải định dạng là ảnh',
                    outOfSize: 'Ảnh tải lên vượt quá dung lượng cho phép',
                    lowestPx: 'Ảnh tải lên dung lượng quá thấp.'
                }
            },
            userInfo: {
                avatar: require("@/assets/images/portrait/small/user-avatar.png"),
                img:null
            },
            img: null
        }
    },
    computed: {
        province: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.cityOptions.find(x => x.value === this.organizationInfo.address.city)),
                    value: this.organizationInfo.address.city
                }
            },
            set(obj) {
                this.organizationInfo.address.city = obj.value;
                this.districtOptions = province.getDistrict(obj.value);
                this.organizationInfo.address.district = this.districtOptions[0].value;
                this.subDistrictOptions = province.getSubDistrict(obj.value, this.organizationInfo.address.district);
                this.organizationInfo.address.street = this.subDistrictOptions[0].value;
            }
        },
        district: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.districtOptions.find(x => x.value === this.organizationInfo.address.district)),
                    value: this.organizationInfo.address.district
                }
            },
            set(obj) {
                this.organizationInfo.address.district = obj.value;
                this.subDistrictOptions = province.getSubDistrict(this.organizationInfo.address.city, obj.value);
                this.organizationInfo.address.street = this.subDistrictOptions[0].value;
            }
        },
        subDistrict: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.subDistrictOptions.find(x => x.value === this.organizationInfo.address.street)),
                    value: this.organizationInfo.address.street
                }
            },
            set(obj) {
                this.organizationInfo.address.street = obj.value
            }
        },
        organizationType: {
            get() {
                return {
                    label: this.getLabelString(this.organizationTypeOptions.find(x => x.value === this.organizationInfo.type)),
                    value: this.organizationInfo.type
                }
            },
            set(obj) {
                this.organizationInfo.type = obj.value
            }
        },
        active: {
            get() {
                return this.organizationInfo.active;
            },
            set(value) {
                this.organizationInfo.active = value ? 1 : 0;
            }
        },
        fullAddressModel: {
            get() {
                let fullAddress = `${this.organizationInfo.address.houseNumber ? this.organizationInfo.address.houseNumber + ', ' : ''}${this.subDistrict.label}, ${this.district.label}, ${this.province.label}`;
                /* eslint-disable */
                this.organizationInfo.address.fullAddress = fullAddress;
                return fullAddress;
            },
        },
        validateForm() {
            return !this.errors.any()
        },
        emailModel :{
            get() {
                let email = this.organizationInfo.email || '';
                return email;
            },
            set(val) {
                this.organizationInfo.email = val;
            }
        }
    },
    created() {
        let organizationInfo = JSON.parse(JSON.stringify(this.data));

        if (!organizationInfo.address) {
            organizationInfo.address = {};
            return this.organizationInfo = organizationInfo;
        }

        this.organizationInfo = organizationInfo;
        this.districtOptions = province.getDistrict(organizationInfo.address.city);
        this.subDistrictOptions = province.getSubDistrict(organizationInfo.address.city, organizationInfo.address.district);
        this.getInfo();
    },
    methods: {
        getInfo: function(){
            this.$oauth.get('/user/current').then((res) => {
                if (res.data.img.includes('infocare')) {
                    this.img = res.data.img.replace('cdn.infocare', 'cdnimage.inviv')
                } else {
                    this.img = res.data.img;
                }
            }).catch(() => {})
        },
        cropSuccess: function(e, fileInput){
            const payload = {
                image: e
            }
            this.$oauth.post('/user/update-avatar', payload).then(() => {
                this.$eventBus.$emit('update-avatar', true);
                this.getInfo();
                return this.$vs.notify({
                    text: 'Cập nhật ảnh đại diện thành công',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            })
        },
        editAvatar: function(){
            this.showUploadImage = true;
        },
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                // this.organizationInfo.adminAccount = 'tempAccount';

                this.$vs.loading();
                this.$oauth.post('/organization/update', this.organizationInfo).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: this.$t('menuOrganization.updateOrganizationSS'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
    },
}
</script>
<style lang="scss">
.custom-edit {
    margin-top: -50% !important;
    opacity: 0.7;
    border-radius: 50%;
    border: 1px solid #ffffff;
    width: 30px;
    height: 30px;
}
.vicp-operate a{
  color: white !important;
  background-color: rgba(var(--vs-primary),1) !important;
  border-radius: 20px !important        ;
}
.vicp-operate a:hover{
  color: white !important;
  background-color: rgba(var(--vs-primary),1) !important;
  border-radius: 20px !important;
}
</style>

