<template>
    <div id="page-user-view">

        <div id="user-data" v-if="userInfo">

            <!-- <vx-card :title="$t('getKey.info')" class="mb-base"> -->

          <div class="vx-row ml-8 mb-4">
              <div class="vx-col" id="avatar-col">
                  <div class="img-container mb-4 vx- row">
                      <img v-if="!img" :src="userInfo.avatar" class="rounded w-full"/>
                      <img v-else :src="img" class="rounded w-full"/>
                  </div>

              </div>
              <div class="vx-col" id="account-info-col-1">
                  <table class="mt-8">
                      <tr>
                          <td class="font-semibold">{{$t('getKey.userName')}}</td>
                          <td>{{ userInfo.username }}</td>
                      </tr>
                      <tr>
                          <td class="font-semibold">Email</td>
                          <td>{{ userInfo.email }}</td>
                      </tr>
                  </table>


              </div>
          </div>
                <div class="vx-row lg:w-full md:w-full w-full ml-3">
                        <div class="vx-col lg:w-1/2 md:w-1/2 w-1/2">
                            <div class="vx-row">

                                <vx-card title="Wehook Push Data" class="mb-base mr-8">
                                    <div class="vx-row ">
                                        <div class="vx-col"><feather-icon  style="color: sandybrown" icon="AlertCircleIcon" svgClasses="h-5 w-5 mr-1  cursor-pointer" class="ml-2"
                                        /> </div>
                                        <div class="vx-col textCy mb-3">{{$t('getKey.describe')}}
                                        </div>
                                        <p class="ml-5">{{$t('getKey.detailedDescription')}}</p>

                                    </div>
                                    <div class="mb-3 mt-4">

                                        <div class="vx-row ">
                                            <div class="vx-col"><feather-icon  style="color: sandybrown" icon="AlertTriangleIcon" svgClasses="h-5 w-5 mr-1  cursor-pointer" class="ml-2"
                                            /> </div>
                                            <div class="vx-col textCy mb-3">{{$t('getKey.attention')}}
                                            </div>
                                            <p class="ml-5">{{$t('getKey.attentionDetail1')}}</p>
                                            <p class="ml-5">{{$t('getKey.attentionDetail2')}}<a href="/user/api_docs?webhook" rel="nofollow">{{$t('getKey.webhook')}}</a></p>
                                        </div>
                                    </div>
                                    <table class="vx-col lg:w-full md:w-full w-full">
                                        <div class="vx-col lg:w-full md:w-full w-full">
                                            <div :key="index" v-for="(item, index) in webhookSetting" class="mt-3"
                                                 style="border-bottom: 1px solid #ccc;">
                                                <div class="vx-row md:mb-1 mb-4">
                                                    <div class="vx-col lg:w-5/6 md:w-5/6 w-5/6" @change="inputCheck()">
                                                        <vs-input v-if="!item.checkUrl" class="w-full" v-model="item.webHook" danger
                                                                  name="webHook"/>
                                                        <vs-input v-if="item.checkUrl" class="w-full" v-model="item.webHook" success
                                                                  name="webHook"/>
                                                    </div>
                                                    <div class="vx-col lg:w-1/6 md:w-1/6 w-1/6" style="padding-top: 10px">
                                                        <vx-tooltip  :text="$t('getKey.checkUrl')" >
                                                            <feather-icon icon="CheckIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer"
                                                                          @click="confirmCheckRecord(item)"/>
                                                        </vx-tooltip>
                                                        <vx-tooltip  :text="$t('getKey.deleteUrl')"  class="ml-5" >
                                                            <feather-icon icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                                                          @click="confirmDeleteRecord(index)"/>
                                                        </vx-tooltip>

                                                    </div>
                                                </div>

                                                <div class="vx-row md:mb-1 mb-4" >
                                                    <br style="color: #ccc">
                                                </div>
                                            </div>
                                            <vs-button class="mt-4"  color="primary"
                                                       type="border"
                                                       icon-pack="feather"
                                                       icon="icon-plus"
                                                       @click="addNewRecord(0)">{{$t('getKey.addLinkUrl')}}</vs-button>
                                        </div>
                                    </table>

                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <div class=" flex flex-wrap items-center justify-end">
                                                <vs-button :disabled="disabledSave" class="ml-auto mt-2" @click="saveChanges">{{isUpdate ? $t('button.update') : $t('button.create')}}
                                                </vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </vx-card>
                            </div>
                        </div>
                    <div class="vx-col lg:w-1/2 md:w-1/2 w-1/2">
                        <div class="vx-col flex-1" id="account-info-col-2">
                            <vx-card :title="$t('getKey.acpetApi')" class="mb-base">
                                <table>
                                    <div class="mb-3">
                                        <div class="vx-row ">
                                            <div class="vx-col"><feather-icon  style="color: sandybrown" icon="AlertTriangleIcon" svgClasses="h-5 w-5 mr-1  cursor-pointer" class="ml-2"
                                            /> </div>
                                            <div class="vx-col textCy mb-3">{{$t('getKey.textAttention')}}</div>
                                        </div>
                                        <p>{{$t('getKey.textTrain1')}}<br>{{$t('getKey.textTrain2')}}<br>{{$t('getKey.textTrain3')}}</p>
                                    </div>
                                    <label class="vs-input--label mt-2 vx-col  w-full">{{$t('getKey.jurisdiction')}}</label>
                                    <div class="w-full mb-5 mt-2" @input="handleRoot">
                                        <vs-checkbox v-if="root" v-model="root" class="ml-4 vx-col" :disabled="true">{{$t('getKey.administration')}}
                                        </vs-checkbox>
                                        <vs-checkbox  v-if="partner" v-model="partner" class="ml-4 vx-col mt-2" :disabled="true">{{$t('getKey.organization')}}</vs-checkbox>
                                    </div>
                                    <label class="vs-input--label mt-2 vx-col  w-full" >{{$t('getKey.secretKey')}}</label>
                                    <div class="vx-row w-full">
                                        <vs-input class="mt-2 vx-col w-full statusText mb-3 " type="text" id="mySecretKey" v-model="isSecretKey" disabled="true">
                                        </vs-input>
                                        <vx-tooltip  class="mt-5" :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                            <feather-icon v-if="isCheckSecretKey" :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppySecretKey"
                                            />
                                            <feather-icon v-if="!isCheckSecretKey" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1  " />

                                        </vx-tooltip></div>
                                    <label class="vs-input--label mt-2 vx-col  w-full" >{{$t('getKey.accessKey')}}</label>
                                    <div class="vx-row w-full">
                                        <vs-input class="mb-5 vx-col w-full statusText" v-model="isKeyApi" type="text" id="myAccessKey" disabled="true"></vs-input>
                                        <vx-tooltip  class="mt-3" :text="$t('weekTime.copy')" style="right: 17px; position: absolute;" >
                                            <feather-icon v-if="isCheckKeyApi"  :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyAccessKey"
                                            />
                                            <feather-icon v-if="!isCheckKeyApi" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 "/>
                                        </vx-tooltip>
                                    </div>
                                    <span>{{$t('getKey.thisKey')}}</span>
                                    <vs-button  class="mt-3 vx-col  w-full"  color="primary"
                                                type="border"
                                                icon-pack="feather"
                                                :icon="iconButton" @click="getKeyApiHandle(statusButton)">{{statusButton}}</vs-button>
                                    <vs-button  class="mt-3 vx-col  w-full"  color="danger"
                                                type="border"
                                                :disabled="checkButtonDelete"
                                                icon-pack="feather"
                                                icon="icon-trash-2" @click="deleteApiHandle()">{{$t('getKey.deleteApiKey')}}</vs-button>
                                </table>
                            </vx-card>
                        </div>
                    </div>
                </div>
            <!-- </vx-card> -->

        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            disabledSave: false,
            checkUrl: null,
            isUpdate: false,
            userInfo: {
                avatar: require("@/assets/images/portrait/small/user-avatar.png")
            },
            root: false,
            partner: false,
            isKeyApi: null,
            isSecretKey: null,
            isCheckSecretKey: false,
            isCheckKeyApi: false,
            webhookSetting: [],
            statusButton: this.$t('getKey.createButton'),
            img: null,
            iconButton: 'icon-plus',
            checkButtonDelete: true,
        }
    },

    watch: {
        '$store.state.userInformation'(val) {
            this.userInfo = Object.assign({}, this.userInfo, val);
        }
    },

    computed: {
    },

    methods: {
        inputCheck(){
            this.disabledSave = true;
        },
        saveChanges(){
            if(this.webhookSetting.find(p => undefined == p.checkUrl)){
                this.disabledSave = true
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('getKey.checkAgain'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            } else {
                this.disabledSave = false
                let url = this.isUpdate ? `/webhook/update` : `/webhook/create`,
                    data = {
                        urls: this.webhookSetting.map(x => x.webHook)
                    };
                this.$vs.loading();
                this.$crm.post(url , data).then((response) => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.create')}` + this.$t('getKey.successful'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }

        },
        confirmCheckRecord(item) {

            this.$vs.loading();
            this.$crm.post(`/webhook/verify-url?url=${item.webHook}`).then((response) => {
                this.webhookSetting = this.webhookSetting.map(el => {
                    if (!el.checkUrl) el.checkUrl = false;
                    else el.checkUrl = true;
                    return el;
                })
                if(response.data == true){
                    this.disabledSave = false;
                    item.checkUrl = true
                    this.$vs.notify({
                        color: 'success',
                        text: this.$t('getKey.linkOke'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                } else {
                    this.disabledSave = true;
                    item.checkUrl = false
                    this.$vs.notify({
                        color: 'danger',
                        text: this.$t('getKey.linkNotOke'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        confirmDeleteRecord(index) {
            this.selectedWebhook = index;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('textIntrusion.titleWarning'),
                text:  `${this.$t('textIntrusion.textDeleteLink')}`,
                accept: this.deleteRecord,
                acceptText: this.$t('textIntrusion.acceptText'),
                cancelText: this.$t('textIntrusion.cancelText')
            })
        },
        deleteRecord() {
            this.disabledSave = false;
            this.$vs.notify({
                color: 'success',
                text: this.$t('getKey.deleteLinkSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            });
            this.webhookSetting.splice(this.selectedWebhook, 1)
        },
        addNewRecord(index) {
            this.webhookSetting.splice(index, 0, {})
        },
        handleRoot(){
            let rootCheck = this.root;
            let partnerCheck = this.partner;
            if(rootCheck == null && partnerCheck == null) {
                this.checkButtonDisabled = true
            } else {
                this.checkButtonDisabled = false
            }
        },
        checkSecretKey(value){
            if(value == null || value == ''){
                this.isCheckSecretKey = false
            } else {
                this.isCheckSecretKey = true
            }
        },
        checkKeyApi(value){
            if(value == null || value == ''){
                this.isCheckKeyApi = false
            } else {
                this.isCheckKeyApi = true
            }
        },
        deleteApiHandle(){
            this.$vs.loading();
            this.$crm.post('/token/partner/delete').then(() => {
                this.isKeyApi = '';
                this.isSecretKey = '';
                this.root = false;
                this.partner = false;
                this.checkButtonDisabled = false;
                this.checkButtonDelete = true;
                this.statusButton = this.$t('getKey.createButton');
                this.iconButton = 'icon-plus';
                this.checkKeyApi(null);
                this.checkSecretKey(null);
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: this.$t('getKey.titleTas'),
                    text: this.$t('getKey.deleteSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getWebhook(){
            this.$vs.loading();
            this.$crm.get('/webhook').then((response) => {
                if(response.data){
                    this.webhookSetting = response.data.urls.map(x => {
                        return {
                            webHook: x,
                            checkUrl: true
                        }
                    })

                    this.isUpdate = true
                } else {
                    this.isUpdate = false
                }
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getInfo: function(){
            this.$oauth.get('/user/current').then((res) => {
                this.img = res.data.img;
            }).catch(() => {})
        },
        coppySecretKey(){
                let copyText = document.getElementById("mySecretKey");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(copyText.value);
                return this.$vs.notify({
                text: this.$t('weekTime.textCopySecretKey'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            })
        },
        coppyAccessKey(){
            let copyText = document.getElementById("myAccessKey");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            return this.$vs.notify({
                    text: this.$t('weekTime.textCopyAccessKey'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
            })
        },
        getKeyApi(){
                this.$vs.loading();
                this.$crm.get('/token/partner/get').then((response) => {
                    if(response.data.length == 0){
                        this.statusButton = this.$t('getKey.createButton')
                        this.checkButtonDelete = true;
                        this.iconButton = 'icon-plus'
                        this.checkKeyApi(null);
                        this.checkSecretKey(null);
                    } else {
                        this.checkButtonDelete = false;
                        this.statusButton = this.$t('getKey.resetButton')
                        this.iconButton = 'icon-edit'
                        this.isKeyApi = response.data.accessKey;
                        this.isSecretKey = response.data.secretKey;
                        this.checkSecretKey(response.data.secretKey);
                        this.checkKeyApi(response.data.accessKey);
                        if(response.data.permissionToken.indexOf('partner') >= 0)
                        {
                            this.partner = true
                        } else {
                            this.partner = false
                        }
                        if(response.data.permissionToken.indexOf('root') >= 0)
                        {
                            this.root = true
                        } else {
                            this.root = false
                        }
                        this.checkSecretKey(response.data.secretKey);
                        this.checkKeyApi(response.data.accessKey);
                        if(response.data.permissionToken.indexOf('partner') >= 0)
                        {
                            this.partner = true
                        } else {
                            this.partner = false
                        }
                        if(response.data.permissionToken.indexOf('root') >= 0)
                        {
                            this.root = true
                        } else {
                            this.root = false
                        }
                    }
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
        },
        getKeyApiHandle(name) {
            if (name == this.$t('getKey.resetButton')) {
                this.$vs.loading();
                this.$crm.post('/token/partner/renew').then((response) => {
                    if (response.data.length == 0) {
                        this.statusButton = this.$t('getKey.createButton')
                        this.iconButton = 'icon-plus';
                        this.checkKeyApi(null);
                        this.checkSecretKey(null);
                    } else {
                        this.checkButtonDelete = false;
                        this.statusButton = this.$t('getKey.resetButton')
                        this.iconButton = 'icon-edit'
                        this.isKeyApi = response.data.accessKey;
                        this.isSecretKey = response.data.secretKey;
                        this.checkSecretKey(response.data.secretKey);
                        this.checkKeyApi(response.data.accessKey);
                        if(response.data.permissionToken.indexOf('partner') >= 0)
                        {
                            this.partner = true
                        } else {
                            this.partner = false
                        }
                        if(response.data.permissionToken.indexOf('root') >= 0)
                        {
                            this.root = true
                        } else {
                            this.root = false
                        }
                        this.$vs.notify({
                            title: this.$t('getKey.titleTas'),
                            color: 'success',
                            text: this.$t('getKey.getKeySuccess'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                    }
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } else {
                this.$vs.loading();
                this.$crm.post('/token/partner/create').then((response) => {
                    if (response.data.length == 0) {
                        this.statusButton = this.$t('getKey.createButton')
                        this.iconButton = 'icon-plus';
                        this.checkKeyApi(null);
                        this.checkSecretKey(null);
                    } else {
                        this.statusButton = this.$t('getKey.resetButton')
                        this.iconButton = 'icon-edit'
                        this.isKeyApi = response.data.accessKey;
                        this.isSecretKey = response.data.secretKey;
                        this.checkButtonDelete = false;
                        this.checkSecretKey(response.data.secretKey);
                        this.checkKeyApi(response.data.accessKey);
                        if(response.data.permissionToken.indexOf('partner') >= 0)
                        {
                            this.partner = true
                        } else {
                            this.partner = false
                        }
                        if(response.data.permissionToken.indexOf('root') >= 0)
                        {
                            this.root = true
                        } else {
                            this.root = false
                        }
                        this.$vs.notify({
                            title: this.$t('getKey.titleTas'),
                            color: 'success',
                            text: this.$t('getKey.createKeySuccess'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                    }
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        }
    },

    created() {
        this.userInfo = Object.assign({}, this.$store.state.userInformation, this.userInfo);
        this.getKeyApi();
        let usrInf = JSON.parse(localStorage.getItem('userInfo'));
        this.userInfo.avatar = usrInf.img
        this.getInfo();
        this.getWebhook();
    },

    mounted() {
        // this.setVerticalNavMenuWidth()
    },
}

</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: .8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

.theme-mode {
    width: 200px;
    height: 100px;
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }

}
</style>
<style lang="scss" scoped>
.statusText {
    font-weight: 500;
    color: #2c2c2c;
}
.textCy {
    margin-left: -25px!important;
    margin-top: -1px!important;
}
</style>

